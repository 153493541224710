<template>
  <v-container fluid pa-0 ma-0 :background="$vuetify.breakpoint.mdAndUp" :background-mobile="$vuetify.breakpoint.smAndDown">
    <v-layout wrap max-width-1400 full-width ma-auto px-3 pt-5>
      <v-flex xs12 md5 lg6 v-if="$vuetify.breakpoint.mdAndUp">
        <v-layout wrap fill-height align-center>
          <v-flex xs12 align-self-end>
            <p  class="graphik-light secondary--text max-width-500" :class="{'font-36': $vuetify.breakpoint.lgAndUp, 'font-30': $vuetify.breakpoint.mdOnly}">{{ $ml.get('landing_fleet_title_1') }} <span class="graphik-bold">{{ $ml.get('landing_fleet_title_2') }}</span></p>

            <p class="graphik-light secondary--text" :class="{'font-25': $vuetify.breakpoint.lgAndUp, 'font-20': $vuetify.breakpoint.mdOnly}">{{ $ml.get('landing_fleet_description') }}</p>
          </v-flex>

          <v-flex xs12 d-flex align-center>
            <v-img
              position="left bottom"
              contain
              :src="images.fleet"
              :lazy-src="images.fleet"
            ></v-img>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 md7 lg6 d-flex :pa-3="$vuetify.breakpoint.mdAndUp" :py-3="$vuetify.breakpoint.smAndDown">
        <v-layout>
          <v-flex shrink>
            <v-img
              position="left bottom"
              contain
              :width="$vuetify.breakpoint.smAndUp ? '80px' : '60px'"
              :height="$vuetify.breakpoint.smAndUp ? '80px' : '60px'"
              :src="images.cuco_avatar"
              :lazy-src="images.cuco_avatar"
            ></v-img>
          </v-flex>

          <v-flex shrink arrow-right>
            <v-img
              position="right"
              contain
              width="25px"
              height="45px"
              :src="images.cuco_dialog_triangle"
              :lazy-src="images.cuco_dialog_triangle"
            ></v-img>
          </v-flex>

          <v-flex grow>
            <v-card class="elevation" :max-width="$vuetify.breakpoint.mdAndUp ? $vuetify.breakpoint.lgAndUp ? '500px' : '450px' : 'auto'" :class="{'dialog-bubble': $vuetify.breakpoint.xsOnly}">
              <v-card-text>
                <p :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-14': $vuetify.breakpoint.xsOnly}" class="graphik-bold secondary--text">{{ $ml.get('general_hello_cuco') }}</p>

                <p :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-13': $vuetify.breakpoint.xsOnly}" class="graphik-light">{{ $ml.get('general_cuco_will_help') }}</p>

                <p class="font-19 graphik-medium secondary--text" v-if="$vuetify.breakpoint.mdAndUp">{{ $ml.get('quoter_fleet_description') }}</p>
                
                <v-form v-model="valid_quote" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="quote.business_name"
                        solo
                        clearable
                        :label="$ml.get('fields_company_name')"
                        required
                        :disabled="g_isLoading"
                        :rules="g_required_rules"
                        class="hidden-shadow quoter-input"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        v-model="quote.unit_number"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        :rules="g_required_rules"
                        :label="$ml.get('fields_unit_number')"
                        :hint="$ml.get('hint_unit_number')"
                        persistent-hint
                        class="hidden-shadow quoter-input"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-text-field
                        v-model="quote.name"
                        solo
                        clearable
                        required
                        :label="$ml.get('fields_contact_name')"
                        :disabled="g_isLoading"
                        :rules="g_required_rules"
                        class="hidden-shadow quoter-input"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 pr-2>
                      <v-text-field
                        v-model="quote.email"
                        solo
                        clearable
                        required
                        :label="$ml.get('fields_email')"
                        :disabled="g_isLoading"
                        :rules="g_email_rules"
                        :hint="$ml.get('hint_email')"
                        class="hidden-shadow quoter-input"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 pl-2>
                      <v-text-field
                        v-model="quote.cellphone"
                        solo
                        clearable
                        required
                        :label="$ml.get('fields_cellphone')"
                        :disabled="g_isLoading"
                        mask="phone"
                        :rules="g_phone_rules"
                        class="hidden-shadow quoter-input"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-form>

                <v-layout row wrap v-if="$vuetify.breakpoint.mdAndUp">
                  <v-flex xs12 pt-3 px-1>
                    <v-checkbox v-model="accept_terms" color="secondary" :label="$ml.get('general_accept_terms')" hide-actions class="ma-0 pa-0 shrink mr-3" :class="{'font-12': $vuetify.breakpoint.smAndDown}"></v-checkbox>
                  </v-flex>
                </v-layout>

                <v-layout row wrap v-if="$vuetify.breakpoint.mdAndUp">
                  <v-flex xs12 pt-3 px-1>
                    <v-btn 
                      color="pantene"
                      depressed large block
                      :disabled="!valid_quote || !accept_terms"
                      :loading="g_isLoading"
                      class="normalcase white--text graphik-bold-italic ma-0 border-radius-5"
                      @click="createQuote"
                    >{{ $ml.get('general_quote') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>

                    <p class="font-12 graphik-light text-xs-center dark-grey--text px-3 mb-0 py-3"><v-icon small class="mr-1" color="primary">fas fa-lock</v-icon> {{ $ml.get('general_protected_data') }}</p>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
        <p class="font-19 graphik-medium secondary--text">{{ $ml.get('quoter_fleet_description') }}</p>

        <v-form v-model="valid_quote">
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                v-model="quote.business_name"
                solo
                clearable
                :label="$ml.get('fields_company_name')"
                required
                :disabled="g_isLoading"
                :rules="g_required_rules"
                class="hidden-shadow quoter-input"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="quote.unit_number"
                solo
                clearable
                required
                :disabled="g_isLoading"
                :rules="g_required_rules"
                :label="$ml.get('fields_unit_number')"
                :hint="$ml.get('hint_unit_number')"
                persistent-hint
                class="hidden-shadow quoter-input"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="quote.name"
                solo
                clearable
                required
                :label="$ml.get('fields_contact_name')"
                :disabled="g_isLoading"
                :rules="g_required_rules"
                class="hidden-shadow quoter-input"
              ></v-text-field>
            </v-flex>

            <v-flex xs6 pr-2>
              <v-text-field
                v-model="quote.email"
                solo
                clearable
                required
                :label="$ml.get('fields_email')"
                :disabled="g_isLoading"
                :rules="g_email_rules"
                :hint="$ml.get('hint_email')"
                class="hidden-shadow quoter-input"
              ></v-text-field>
            </v-flex>

            <v-flex xs6 pl-2>
              <v-text-field
                v-model="quote.cellphone"
                solo
                clearable
                required
                :label="$ml.get('fields_cellphone')"
                :disabled="g_isLoading"
                mask="phone"
                :rules="g_phone_rules"
                class="hidden-shadow quoter-input"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>

        <v-layout row wrap>
          <v-flex xs12 pt-3 px-1>
            <v-checkbox v-model="accept_terms" color="secondary" :label="$ml.get('general_accept_terms')" hide-actions class="ma-0 pa-0 shrink mr-3" :class="{'font-12': $vuetify.breakpoint.smAndDown}"></v-checkbox>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 pt-3 px-1>
            <v-btn 
              color="pantene"
              depressed large block
              :disabled="!valid_quote || !accept_terms"
              :loading="g_isLoading"
              class="normalcase white--text graphik-bold-italic ma-0 border-radius-5"
              @click="createQuote"
            >{{ $ml.get('general_quote') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>

            <p class="font-12 graphik-light text-xs-center dark-grey--text px-3 mb-0 py-3"><v-icon small class="mr-1" color="primary">fas fa-lock</v-icon> {{ $ml.get('general_protected_data') }}</p>
          </v-flex>
        </v-layout>

        <v-img
          position="left bottom"
          contain
          :src="images.fleet"
          :lazy-src="images.fleet"
        ></v-img>
      </v-flex>

      <v-flex xs12 text-xs-center>
        <p class="white--text font-20 graphik-medium mb-2">{{ $ml.get('general_read_more') }}</p>
        <v-icon color="white" class="mb-3">fas fa-chevron-circle-down</v-icon>
      </v-flex>
    </v-layout>

    <quoter-dialog-buy-with-cash :show="dialog" @close="dialog = false" />
    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'QuoterFleet',
  data () {
    return {
      accept_terms: false,
      valid_quote: false,
      dialog: false,
      quote: {
        business_name: '',
        unit_number: '',
        name: '',
        email: '',
        cellphone: '',
        type: 'fleet'
      },
      images: {
        fleet: require('@/assets/img/images/banner_fleet_landing.png'),
        cuco_avatar: require('@/assets/img/images/cuco_avatar.png'),
        cuco_dialog_triangle: require('@/assets/img/images/cuco_dialog_triangle.png')
      }
    }
  },
  watch: {
    g_user () {
      this.quote.name = this.g_user.first_name
      this.quote.email = this.g_user.email
      this.quote.cellphone = this.g_user.cellphone
    }
  },
  components: {
    'quoter-dialog-buy-with-cash': () => import(/* webpackPrefetch: true */ './QuoterDialogBuyWithCash')
  },
  methods: {
    // CRUD
    createQuote () {
      this.g_isLoading = true

      this.$api.requestQuote(
        {...this.quote, campaign_uuid: localStorage.radial},
        () => {
          this.$analytics('quoter_fleet_create_quote')
          this.dialog = true
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.createQuote()
        }, () => {})
      )
    }
  },
  mounted () {
    if (this.g_auth.access_token) this.$authGetUser()
  }
}
</script>

<style scoped>
  .background {
    background: linear-gradient(180deg, #FCFBFF 70%, #7E5AE8 30%);
  }

  .background-mobile {
    background: linear-gradient(180deg, #FCFBFF 85%, #7E5AE8 15%);
  }

  .elevation {
    box-shadow: -3px -3px 4px 1px rgba(130, 131, 131, 0.08), 5px 5px 6px 1px rgba(130, 131, 131, 0.14);
  }

  .arrow-right {
    z-index: 1;
    margin-top: 20px;
  }

  .rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-top: -20px !important;
    margin-bottom: -10px !important;
    z-index: 1;
  }

  .dialog-bubble {
    max-width: calc(100% - 85px);
  }
</style>